import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import logo from "../img/logo2.svg";
import {
    Chart as ChartJS,
    BarElement,
    Tooltip,
    Title,
    CategoryScale,
    LinearScale,
} from "chart.js";

// Register the required components and controllers
ChartJS.register(BarElement, Tooltip, Title, CategoryScale, LinearScale);

const Charts = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState("county"); // 'county' or 'city'
    let apiBaseUrl = process.env.REACT_APP_API_URL;

    // Function to fetch data based on the view (county or city)
    const fetchData = async (viewType, endpoint) => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${apiBaseUrl}/api/v1/${endpoint}`
            );

            // Sort the data alphabetically by city or county name
            const sortedData = response.data.sort((a, b) => {
                const nameA =
                    viewType === "city"
                        ? a.RES_CITY.toLowerCase()
                        : a.RES_COUNTY.toLowerCase();
                const nameB =
                    viewType === "city"
                        ? b.RES_CITY.toLowerCase()
                        : b.RES_COUNTY.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });

            setData(sortedData); // Set the sorted data
        } catch (error) {
            console.error(`Error fetching ${viewType} data`, error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch county data initially
    useEffect(() => {
        fetchData("county", "res-county-counts");
    }, [apiBaseUrl]);

    // Toggle between county and city data
    const handleViewChange = (viewType) => {
        setView(viewType);
        const endpoint =
            viewType === "city" ? "res-city-counts" : "res-county-counts";
        fetchData(viewType, endpoint);
    };

    const chartData = {
        labels: data.map((item) =>
            view === "city" ? item.RES_CITY : item.RES_COUNTY
        ),
        datasets: [
            {
                label: "Voters",
                data: data.map((item) => item.count),
                backgroundColor: "rgba(0, 0, 0, 1.00)",
                borderColor: "rgba(0, 0, 0, 1)",
                borderWidth: 0,
            },
        ],
    };

    const options = {
        indexAxis: "y",
        scales: {
            x: {
                beginAtZero: true,
                title: { display: false, text: "Count" },
                grid: { color: "rgba(0, 0, 0, 1)" },
            },
            y: {
                title: {
                    display: false,
                    text: view === "city" ? "City" : "County",
                },
                ticks: {
                    autoSkip: false,
                    font: { size: 14, weight: "bold" }, // Increased font size
                    color: "rgba(0, 0, 0, 1)",
                    callback: function (value, index) {
                        const item = data[index];
                        if (!item) return ""; // Prevent "undefined" errors
                        const label =
                            view === "city" ? item.RES_CITY : item.RES_COUNTY;
                        const count = item.count.toLocaleString();
                        return `${label} (${count})`; // Format: Jackson (97,066)
                    },
                },
                grid: { color: "rgba(0, 0, 0, 1)" },
            },
        },
        plugins: { legend: { display: false }, title: { display: false } },
        maintainAspectRatio: false,
    };

    // Calculate height based on number of data items, with a minimum height.
    const dynamicHeight = Math.max(data.length * 30, 500); // 30px per item, with a minimum of 500px

    return (
        <div
            className="table-container"
            style={{ height: `${dynamicHeight}px` }}>
            <button
                onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("searchFilters");
                    window.location.href = "/";
                }}
                className="header-button logout-button">
                Logout
            </button>
            <button
                onClick={() => {
                    window.location.href = "/charts";
                }}
                className="header-button charts-button">
                Charts
            </button>
            <div className="header">
                <img src={logo} alt="Voter Data Logo" className="logo" />
                <button
                    className="back-button"
                    onClick={() => {
                        window.location.href = "/search";
                    }}>
                    ◀ Search
                </button>
            </div>
            <div className="centered">
                <button
                    onClick={() => handleViewChange("county")}
                    className="toggle-button county-toggle">
                    County Distribution
                </button>
                <button
                    onClick={() => handleViewChange("city")}
                    className="toggle-button city-toggle">
                    City Distribution
                </button>
            </div>
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <Bar data={chartData} options={options} />
            )}
        </div>
    );
};

export default Charts;
