import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo2.svg"; // Adjust the path if necessary

function Login() {
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const apiUrl = process.env.REACT_APP_API_URL;

            const response = await fetch(`${apiUrl}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ password }),
            });

            if (response.ok) {
                localStorage.setItem("token", "authenticated");
                navigate("/search");
            } else {
                alert("Incorrect password");
            }
        } catch (error) {
            console.error("Error during login:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="login-container">
            <img src={logo} alt="Voter Data Logo" className="logo login-logo" />
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Submit</button>
            </form>
            <p className="ver pill">v1.43.4</p>
        </div>
    );
}

export default Login;
