import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../img/logo2.svg";

const Search = () => {
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(20);
    const [searchQueries, setSearchQueries] = useState({});
    const [totalEntries, setTotalEntries] = useState(0);
    const [filteredEntries, setFilteredEntries] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    let apiBaseUrl = process.env.REACT_APP_API_URL;

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("searchFilters");
        navigate("/");
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        if (location.state?.from !== "login") {
            const storedFilters = JSON.parse(
                localStorage.getItem("searchFilters")
            );
            if (storedFilters) {
                setSearchQueries(storedFilters);
                applySearch(limit, storedFilters); // Trigger search with stored filters
            } else {
                applySearch(limit, searchQueries); // Initial fetch with default queries
            }
        } else {
            applySearch(limit, searchQueries); // Initial fetch with default queries
        }
        fetchTotalEntries();
    }, []);

    const fetchTotalEntries = async () => {
        try {
            const response = await axios.get(
                `${apiBaseUrl}/api/v1/total-count`,
                {
                    headers: { Authorization: localStorage.getItem("token") },
                }
            );
            setTotalEntries(response.data);
        } catch (error) {
            console.error("Error fetching total entries:", error);
        }
    };

    const fetchFilteredEntries = async (queries) => {
        try {
            const queryParams = new URLSearchParams();
            Object.keys(queries).forEach((key) => {
                if (queries[key]) {
                    queryParams.append(key, queries[key].toLowerCase());
                }
            });

            const response = await axios.get(
                `${apiBaseUrl}/api/v1/filtered-count?${queryParams.toString()}`,
                {
                    headers: { Authorization: localStorage.getItem("token") },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching filtered entries:", error);
            return null;
        }
    };

    const applySearch = async (limit, queries) => {
        setLoading(true);
        try {
            const queryParams = new URLSearchParams({ limit });
            Object.keys(queries).forEach((key) => {
                if (queries[key]) {
                    queryParams.append(key, queries[key].toLowerCase());
                }
            });

            const response = await axios.get(
                `${apiBaseUrl}/api/v1/voters?${queryParams.toString()}`,
                {
                    headers: { Authorization: localStorage.getItem("token") },
                }
            );
            const cleanedData = response.data.map((row) => {
                Object.keys(row).forEach((key) => {
                    if (
                        row[key] === null ||
                        row[key] === "NaN" ||
                        row[key] === "nan"
                    ) {
                        row[key] = "";
                    }
                });
                return row;
            });
            setData(cleanedData);

            const filteredCount = await fetchFilteredEntries(queries);
            setFilteredEntries(filteredCount);
        } catch (error) {
            console.error("Error fetching voters:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
    };

    const handleSearchChange = (event, column) => {
        setSearchQueries({ ...searchQueries, [column]: event.target.value });
    };

    const handleSearchClick = () => {
        const roundedSearchQueries = { ...searchQueries };
        if (searchQueries["General_TurnoutPropensity"]) {
            const turnoutValues = searchQueries["General_TurnoutPropensity"]
                .split(",")
                .map((value) => parseFloat(value).toFixed(2)) // Round each value
                .join(","); // Join them back with commas
            roundedSearchQueries["General_TurnoutPropensity"] = turnoutValues;
        }

        applySearch(limit, roundedSearchQueries);

        // Save filters to local storage
        localStorage.setItem("searchFilters", JSON.stringify(searchQueries));
    };

    const handleResetClick = () => {
        setSearchQueries({});
        setFilteredEntries(null);
        applySearch(limit, {});
        localStorage.removeItem("searchFilters");
    };

    const handleKeyDown = (event) => {
        if (
            event.key === "Enter" &&
            Object.values(searchQueries).some((query) => query.trim() !== "")
        ) {
            handleSearchClick();
        }
    };

    const handleDownloadClick = () => {
        const queryParams = new URLSearchParams();
        Object.keys(searchQueries).forEach((key) => {
            if (searchQueries[key]) {
                queryParams.append(key, searchQueries[key].toLowerCase());
            }
        });
        window.location.href = `${apiBaseUrl}/api/v1/download?${queryParams.toString()}`;
    };

    const handleNavigation = (path) => {
        localStorage.setItem("searchFilters", JSON.stringify(searchQueries));
        navigate(path);
    };

    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const columns = [
        "Mapping Value",
        "FIRST_NAME",
        "MIDDLE_NAME",
        "LAST_NAME",
        "SUFFIX",
        "General_Grade",
        "Primary_Grade",
        "General_TurnoutPropensity",
        "CalculatedParty",
        "HouseholdParty",
        "RESIDENTIAL_ADDRESS",
        "HOUSE_NUM",
        "STREET_NAME",
        "STREET_TYPE",
        "PRE-DIRECTION",
        "POST-DIRECTION",
        "RES_CITY",
        "RES_STATE",
        "RES_ZIP_CODE",
        "RES_COUNTY",
        "PrimaryPhone",
        "PrimaryLandline",
        "PrimaryMobile",
        "EFFECTIVE_REGN_DATE",
        "RegistrationDate",
        "STATUS",
        "DATE_VOTED",
        "PRECINCT_CODE",
        "PRECINCT_NAME",
        "MAILING_ADDRESS",
        "MAIL_CITY",
        "MAIL_STATE",
        "MAIL_ZIP_CODE",
        "US",
        "MS",
        "CONG",
        "SC",
        "PSC",
        "TC",
        "DA",
        "SEN",
        "REP",
        "CA",
        "CHC",
        "CIR",
        "CNT",
        "LEV",
        "COCT",
        "JUD",
        "SCHC",
        "SCIR",
        "SUP",
        "SUPR",
        "EC",
        "JC",
        "JCJ",
        "CON",
        "MUN",
        "WARD",
        "WARDP",
        "MP",
        "SCHD",
        "SCHB",
        "SBAL",
        "FIRE",
        "FCD",
        "WSD",
        "IPD",
        "VoterKey",
        "HHRecId",
        "HHMemberId",
        "HHCode",
    ];

    const columnDisplayNames = {
        "Mapping Value": "Voter ID",
        FIRST_NAME: "First Name",
        MIDDLE_NAME: "Middle Name",
        LAST_NAME: "Last Name",
        SUFFIX: "Suffix",
        General_Grade: "General Grade",
        Primary_Grade: "Primary Grade",
        General_TurnoutPropensity: "Turnout Odds",
        CalculatedParty: "Calculated Party",
        HouseholdParty: "Household Party",
        RESIDENTIAL_ADDRESS: "Residential Address",
        HOUSE_NUM: "House Number",
        STREET_NAME: "Street Name",
        STREET_TYPE: "Street Type",
        "PRE-DIRECTION": "Pre-Direction",
        "POST-DIRECTION": "Post-Direction",
        RES_CITY: "City",
        RES_STATE: "State",
        RES_ZIP_CODE: "Zip Code",
        RES_COUNTY: "County",
        PrimaryPhone: "Primary Phone",
        PrimaryLandline: "Landline",
        PrimaryMobile: "Mobile",
        EFFECTIVE_REGN_DATE: "Effective Registration Date",
        RegistrationDate: "Registration Date",
        STATUS: "Status",
        DATE_VOTED: "Date Voted",
        PRECINCT_CODE: "Precinct Code",
        PRECINCT_NAME: "Precinct Name",
        MAILING_ADDRESS: "Mailing Address",
        MAIL_CITY: "Mail City",
        MAIL_STATE: "Mail State",
        MAIL_ZIP_CODE: "Mail Zip Code",
        US: "US House District",
        MS: "Mississippi House District",
        CONG: "Congressional District",
        SC: "Supreme Court District",
        PSC: "Public Service Commissioner District",
        TC: "Transportation Commissioner District",
        DA: "District Attorney",
        SEN: "State Senate District",
        REP: "State Representative District",
        CA: "Court of Appeals District",
        CHC: "Chancery Court District",
        CIR: "Circuit Court District",
        CNT: "County",
        LEV: "Levee Board District",
        COCT: "County Court",
        JUD: "Justice Court",
        SCHC: "School Board Chair",
        SCIR: "School Board Circuit",
        SUP: "Supervisor District",
        SUPR: "Surrogate District",
        EC: "Election Commissioner",
        JC: "Justice Court Judge",
        JCJ: "Juvenile Court Judge",
        CON: "Constable",
        MUN: "Municipality",
        WARD: "Ward",
        WARDP: "Ward Position",
        MP: "Municipal Position",
        SCHD: "School District",
        SCHB: "School Board",
        SBAL: "School Board At Large",
        FIRE: "Fire District",
        FCD: "Flood Control District",
        WSD: "Water and Sewer District",
        IPD: "Industrial Park District",
        VoterKey: "Voter Key",
        HHRecId: "Household Record ID",
        HHMemberId: "Household Member ID",
        HHCode: "Household Code",
    };

    return (
        <div className="table-container" onKeyDown={handleKeyDown}>
            <button
                onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("searchFilters");
                    window.location.href = "/";
                }}
                className="header-button logout-button">
                Logout
            </button>
            <button
                onClick={() => {
                    window.location.href = "/charts";
                }}
                className="header-button charts-button">
                Charts
            </button>
            <div className="header">
                <img src={logo} alt="Voter Data Logo" className="logo" />
                <div className="pill-container">
                    <span className="pill total-entries">
                        Total: {formatNumberWithCommas(totalEntries)}
                    </span>
                    {filteredEntries !== null &&
                        filteredEntries !== totalEntries && (
                            <span className="pill filtered-entries">
                                Filtered:{" "}
                                {formatNumberWithCommas(filteredEntries)}
                            </span>
                        )}
                    <p className="download-info">
                        ℹ️ Downloads are capped at 50,000 entries.
                    </p>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="download-icon-wrap">
                            <p
                                className="download-icon"
                                onClick={handleDownloadClick}>
                                <span className="tooltip">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32px"
                                        height="32px"
                                        viewBox="0.82 2.55 10.35 14.08">
                                        <path d="m11.16 16.153a.477.477 0 0 1 -.476.475h-9.368a.476.476 0 0 1 -.475-.475v-13.107a.476.476 0 0 1 .475-.475h6.95l2.893 2.893zm-1.11-9.925h-1.991a.575.575 0 0 1 -.574-.573v-1.976h-5.535v11.84h8.102zm-1.234 5.604-2.428 2.428a.554.554 0 0 1 -.784 0l-2.428-2.428a.554.554 0 1 1 .783-.784l1.483 1.482v-5.12a.554.554 0 1 1 1.108 0v5.12l1.482-1.482a.554.554 0 0 1 .784.783z"></path>
                                    </svg>
                                    <span className="tooltiptext">
                                        Download CSV
                                    </span>
                                </span>
                            </p>
                        </th>
                        {columns.map((column) => (
                            <th key={column}>
                                {columnDisplayNames[column] ||
                                    column.replace(/_/g, " ")}
                                <div>
                                    <input
                                        type="text"
                                        placeholder={`Search ${
                                            columnDisplayNames[column] ||
                                            column.replace(/_/g, " ")
                                        }`}
                                        value={searchQueries[column] || ""}
                                        onChange={(e) =>
                                            handleSearchChange(e, column)
                                        }
                                    />
                                </div>
                            </th>
                        ))}
                    </tr>
                    <tr className="controls-row">
                        <th>ROW</th>
                        <th colSpan={columns.length + 1}>
                            <div className="controls">
                                <label htmlFor="limit">
                                    Number of results:
                                </label>
                                <select
                                    id="limit"
                                    value={limit}
                                    onChange={handleLimitChange}>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                </select>
                                <button
                                    className="download-button"
                                    onClick={handleDownloadClick}>
                                    DOWNLOAD CSV
                                </button>
                                <button
                                    className="reset-button"
                                    onClick={handleResetClick}>
                                    RESET
                                </button>
                                <button
                                    className="go-button"
                                    onClick={handleSearchClick}>
                                    GO
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td
                                colSpan={columns.length + 1}
                                className="loading">
                                LOADING...
                            </td>
                        </tr>
                    ) : data.length > 0 ? (
                        data.map((row, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                {columns.map((column) => (
                                    <td key={column}>
                                        {column === "Mapping Value" ? (
                                            <span
                                                className="clickable"
                                                onClick={() =>
                                                    handleNavigation(
                                                        `/history?mappingValue=${row[column]}`
                                                    )
                                                }>
                                                {row[column]}
                                            </span>
                                        ) : column ===
                                              "General_TurnoutPropensity" &&
                                          row[column] ? (
                                            parseFloat(row[column]).toFixed(2) // Round to 2 decimal places for display
                                        ) : (
                                            row[column]
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={columns.length + 1}
                                className="no-results">
                                NO RESULTS
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Search;
